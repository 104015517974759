import React from 'react'
import { Link } from 'gatsby'

const Navbar = ({ topnav }) => {
  let fixedTop = null

  if (topnav === 'fixed') {
    fixedTop = 'is-fixed-top'
  } else {
    fixedTop = ''
  }

  return (
    <div className={`navigation ${fixedTop}`}>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-10">
            <div className="columns">
              <div className="column is-3 nav-link">
                <Link to="/energetisch-renoveren/" activeClassName="schuin">
                  energetisch renoveren
                </Link>
              </div>
              <div className="column is-3 nav-link">
                <Link to="/wie/" activeClassName="schuin">
                  wie
                </Link>
              </div>
              <div className="column is-3 nav-link">
                <Link to="/projecten/" activeClassName="schuin">
                  projecten
                </Link>
              </div>
              <div className="column is-3 nav-link">
                <Link to="/contact/" activeClassName="schuin">
                  contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
