import React from 'react'

const ColWrap = ({ children }) => {
  return (
    <>
      <div className="columns is-centered">
        <div className="column is-10">
          <div className="columns is-6 is-variable is-centered">{children}</div>
        </div>
      </div>
    </>
  )
}

export default ColWrap
