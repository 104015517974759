import React from 'react'
import { Link } from 'gatsby'
import logoImg from '../img/logo.png'

const Logo = () => {
  return (
    <section>
      <div className="container">
        <div className="has-text-centered">
          <Link to="/" className="logo">
            <img src={logoImg} alt="Logo Bijninterieur.be" title="Home" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Logo
