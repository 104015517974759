import React from 'react'
import { Link } from 'gatsby'
import logoFooter from '../img/logo-footer.png'

const LogoFooter = () => {
  return (
    <div className="footer-logo has-text-centered">
      <Link to="/">
        <img src={logoFooter} alt="Logo Bijninterieur.be" title="Home" />
      </Link>
    </div>
  )
}

export default LogoFooter
