import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

class CookieBar extends React.Component {
  state = {
    cookieSet: false,
    cookieCSSClass: '',
  }

  handleCookies = () => {
    this.setState({ cookieSet: true, cookieCSSClass: 'is-hidden' })
  }

  render() {
    const { wordpressPage: content } = this.props.data

    return (
      <div
        id="cookie-bar"
        className={`cookie-bar has-text-centered ${this.state.cookieCSSClass}`}
      >
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6">
              <div className="cookie-intro">{content.acf.gdpr_boodschap}</div>
              <a
                className="cookie-btn cookie-accept"
                onClick={this.handleCookies}
              >
                Aanvaarden
              </a>
              <Link className="cookie-btn" to="/privacy">
                Meer Info
              </Link>
            </div>
            <div className="cookie-cross">
              <div
                onClick={this.handleCookies}
                className="cookie-cross-wrap cookie-accept"
              >
                <span className="up"></span>
                <span className="down"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(wordpress_id: { eq: 153 }) {
          acf {
            gdpr_boodschap
          }
        }
      }
    `}
    render={data => <CookieBar data={data} />}
  />
)
