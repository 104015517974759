import React from 'react'
import { Link } from 'gatsby'
import { FaLinkedin } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import ColWrap from './Helpers/ColWrap'
import LogoFooter from './LogoFooter'

const Footer = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getUTCFullYear().toString()
  const iconStyles = {
    color: '#444',
    className: 'social-share-icon-contact',
  }

  return (
    <footer className="footer color-bg section medium">
      <div className="container">
        <LogoFooter />
        <div className="footer-columns">
          <div className="columns is-centered">
            <div className="column is-6">
              <div className="columns is-8 is-variable is-centered">
                <div className="column has-text-right-tablet is-6">
                  <h4>sitemap</h4>
                  <ul>
                    <li>
                      <Link to="/">home</Link>
                    </li>
                    <li>
                      <Link to="/energetisch-renoveren">
                        energetisch renoveren
                      </Link>
                    </li>
                    <li>
                      <Link to="/wie">wie</Link>
                    </li>
                    <li>
                      <Link to="/projecten">projecten</Link>
                    </li>
                    <li>
                      <Link to="/contact">contact</Link>
                    </li>
                  </ul>
                </div>

                <div className="column is-6">
                  <h4>contact</h4>
                  <div className="content">
                    <p>
                      Johan Bijn
                      <br />
                      <a href="tel:+32 (0)473 81 36 55">+32 (0)473 81 36 55</a>
                      <br />
                      <a href="mailto:johan@bijninterieur.be">
                        johan@bijninterieur.be
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.linkedin.com/in/johanbijn/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconContext.Provider value={iconStyles}>
                          <FaLinkedin />
                        </IconContext.Provider>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="legal has-text-centered">
          {currentYear}© Bijninterieur |{' '}
          <Link to="/privacy">Privacy en cookies</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
