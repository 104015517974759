import React from 'react'
import Helmet from 'react-helmet'
import Navbar from './Navbar'
import Logo from './Logo'
import Footer from './Footer'
import CookieBar from './CookieBar'
import './all.sass'

import favicon16 from '../img/bijn-favicon-16.png'
import favicon32 from '../img/bijn-favicon-32.png'
import favicon64 from '../img/bijn-favicon-64.png'

const TemplateWrapper = ({ children }) => (
  <div className="site-wrap">
    <Helmet>
      <html lang="nl" />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link
        rel="shortcut icon"
        type="image/png"
        sizes="64x64"
        href={favicon64}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, viewport-fit=cover"
      />
    </Helmet>
    <header className="header">
      <Logo />
      <Navbar />
    </header>
    <main className="main">{children}</main>
    <Footer />
    <CookieBar />
  </div>
)

export default TemplateWrapper
